import React from 'react';
import { theme } from '../theme';
import { NavLink } from './Nav/NavLink';
import { Social } from './Social';
import { Links } from '../constants';
import { Container } from './Container';

export interface FooterProps {}

export interface FooterState {}
export class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <Container padding={['all']} background="grayDarker">
        <div
          css={{
            textAlign: 'center',
            display: 'flex',
            margin: '0 auto 2rem',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.media.medium]: {
              flexDirection: 'row',
            },
          }}
        >
          <NavLink
            key={`footer-home`}
            to="/"
            css={{
              padding: '.5rem 0',
              color: theme.color.grayLightest,
              [theme.media.medium]: {
                padding: 0,
                marginLeft: '1rem',
              },
            }}
          >
            Home
          </NavLink>
          {Links.map(({ to, href, text }) => (
            <NavLink
              css={{
                padding: '.5rem 0',
                color: theme.color.grayLightest,
                [theme.media.medium]: {
                  padding: 0,
                  marginLeft: '1rem',
                },
              }}
              key={text}
              to={to}
              href={href}
            >
              {text}
            </NavLink>
          ))}
        </div>
        <div
          css={{
            marginBottom: '2rem',
            color: theme.color.grayLightest,
            textAlign: 'center',
          }}
        >
          <Social />
        </div>
        <div
          css={{
            fontSize: '.8rem',
            color: theme.color.grayLighter,
            textAlign: 'center',
          }}
        >
          Copyright © 2018 The Palmer Group. All Rights Reserved.
        </div>
      </Container>
    );
  }
}
