import React from 'react';
import { theme } from '../theme';

export interface SocialProps {}

export const Social: React.SFC<SocialProps> = props => {
  return (
    <>
      <a
        css={{
          display: 'inline-block',
          color: 'inherit',
          transition: 'color 200ms ease-out',
          '&:hover': {
            color: theme.color.twitter,
          },
          marginRight: '1rem',
        }}
        href="https://twitter.com/palmergrouphq"
        target="_blank"
        rel="noopener"
        title="Palmer Group Twitter"
      >
        <svg fill="currentColor" viewBox="0 0 24 24" height="20" width="20">
          <path d="M23.954 4.569a10 10 0 0 1-2.825.775 4.958 4.958 0 0 0 2.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 0 0-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 0 0-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 0 1-2.228-.616v.061a4.923 4.923 0 0 0 3.946 4.827 4.996 4.996 0 0 1-2.212.085 4.937 4.937 0 0 0 4.604 3.417 9.868 9.868 0 0 1-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 0 0 7.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 0 0 2.46-2.548l-.047-.02z" />
        </svg>
      </a>
      <a
        css={{
          display: 'inline-block',
          color: 'inherit',
          transition: 'color 200ms ease-out',
          '&:hover': {
            color: theme.color.github,
          },
          marginRight: '1rem',
        }}
        href="https://github.com/palmerhq"
        target="_blank"
        rel="noopener"
        title="Palmer Group GitHub"
      >
        <svg fill="currentColor" viewBox="0 0 24 24" height="20" width="20">
          <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
        </svg>
      </a>

      <a
        css={{
          display: 'inline-block',
          color: 'inherit',
          transition: 'color 200ms ease-out',
          '&:hover': {
            color: theme.color.linkedin,
          },
          marginRight: '1rem',
        }}
        href="https://www.linkedin.com/company/the-palmer-group/"
        target="_blank"
        rel="noopener"
        title="The Palmer Group LinkedIn"
      >
        <svg fill="currentColor" height="20" width="20" viewBox="0 0 18 18">
          <path d="M18 18h-4v-6.75c0-1.06-1.19-1.94-2.25-1.94S10 10.19 10 11.25V18H6V6h4v2c.66-1.07 2.36-1.76 3.5-1.76 2.5 0 4.5 2.04 4.5 4.51V18zM4 18H0V6h4v12zM2 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
        </svg>
      </a>
      <a
        css={{
          display: 'inline-block',
          color: 'inherit',
          transition: 'color 200ms ease-out',
          '&:hover': {
            color: theme.color.purple,
          },
          marginRight: '1rem',
          position: 'relative',
        }}
        href="https://spectrum.chat/palmer"
        target="_blank"
        rel="noopener"
        title="The Palmer Group Spectrum"
      >
        <svg fill="currentColor" height="20" width="20" viewBox="0 0 16 16">
          <path d="M0,1.067A1.067,1.067,0,0,1,1.067,0H2A14,14,0,0,1,16,14v.933A1.067,1.067,0,0,1,14.933,16H9.067A1.067,1.067,0,0,1,8,14.933V14A6,6,0,0,0,2,8H1.067A1.067,1.067,0,0,1,0,6.933Z" />
        </svg>
        <span
          css={{
            position: 'absolute',
            display: 'inline-block',
            right: -3,
            bottom: 0,
            width: 8,
            height: 8,
            borderRadius: 4,
            backgroundColor: '#2bdb66',
            pointerEvents: 'none',
          }}
        />
      </a>
    </>
  );
};

(Social as any).displayName = 'Social';
